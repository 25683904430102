import * as React from 'react';
import './Header.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IDispatch, RootState } from '../store/rootState';
import { UiActions } from '../store/ui/actions';
import SearchIcon from '@material-ui/icons/Search';

interface StateProps {
}

export interface DispatchProps {
  uiActions: typeof UiActions;
}

type Props = StateProps & DispatchProps;

class HeaderImpl extends React.Component<Props> {
  refInstall = React.createRef<HTMLDivElement>();
  deferredPrompt: any;

  componentDidMount() {
  }

  render() {
    return (
      <div className="header">
        <img src="/images/logo512.png" className="header_logoImg" />
        <div className="headerText">
          Magnifier
          <div className="textOnline">
            Online
          </div>
          <div className="textBeta">
            Beta
          </div>
        </div>
      </div>
    );
  }
}

export const Header: any = connect<StateProps, DispatchProps, never, never>(
  (state: RootState) => ({
    page: state.ui.page,
  }),
  (dispatch: IDispatch) => ({
    uiActions: bindActionCreators(UiActions, dispatch)
  })
)(HeaderImpl);