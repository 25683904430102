import * as React from 'react';
import './Settings.css';
import UAParser from 'ua-parser-js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IDispatch, RootState } from '../store/rootState';
import { UiActions } from '../store/ui/actions';
import HomeIcon from '@mui/icons-material/Home';
import { UiPage } from '../store/ui/reducer';
import { Header } from './Header';
import { Link } from 'react-router-dom';
import Ad from './Ad';

interface StateProps {
}

export interface DispatchProps {
  uiActions: typeof UiActions;
}

type Props = StateProps & DispatchProps;

class SettingsImpl extends React.Component<Props> {
  refInstall = React.createRef<HTMLDivElement>();

  componentDidMount() {
  }

  render() {
    const note = new UAParser().getDevice().type !== 'mobile' ? `Note: This site is optimized to be used on a smartphone or a tablet.` : ``;
    return (
      <div className="settings_container">
        <Header />
        <div className="settings_content">
          <div className="pageTitle">Settings</div>
          To be implemented soon. Come back. :)
          <Link className="settings_homeButton" to="/">
            <HomeIcon /> Home
          </Link>
        </div>
        <div className="settings_footer">
          { <Ad dataAdSlot='6962620907' /> }
        </div>
      </div>
    );
  }
}

export const Settings: any = connect<StateProps, DispatchProps, never, never>(
  (state: RootState) => ({
  }),
  (dispatch: IDispatch) => ({
    uiActions: bindActionCreators(UiActions, dispatch)
  })
)(SettingsImpl);