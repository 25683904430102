import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Landing } from './components/Landing';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AnyAction, Store } from 'redux';
import { RootState } from './store/rootState';
import { createStore } from 'redux';
import { rootReducer } from './store/rootReducer';
import { Provider } from 'react-redux';
import { About } from './components/About';
import { Main } from './components/Main';
import { Settings } from './components/Settings';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

let store: Store<RootState, AnyAction> | null = createStore(rootReducer as any) as Store<RootState>;

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCV5kjNWcpfSVycqS2VZuA7CD2WtMVy2J4",
  authDomain: "magnifier-online.firebaseapp.com",
  projectId: "magnifier-online",
  storageBucket: "magnifier-online.firebasestorage.app",
  messagingSenderId: "272091458037",
  appId: "1:272091458037:web:c983026cf88316a94098bc",
  measurementId: "G-HZJ4K054N3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/magnifier" element={<Main />} />
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
