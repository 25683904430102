import * as React from 'react';
import './VideoFrame.css';
import { connect } from 'react-redux';
import { IDispatch, RootState } from '../store/rootState';
import { Slider } from './Slider';

interface OwnProps {
  setVideoElement: (videoRef: React.RefObject<HTMLVideoElement>) => void;
}

interface StateProps {
  isFlashOn: boolean;
  isFreezeOn: boolean;
  isInvertOn: boolean;
}

export interface DispatchProps {
}

type Props = OwnProps & StateProps & DispatchProps;

class VideoFrameImpl extends React.Component<Props> {
  state = {
    zoom: 1,
    minZoom: 0,
    maxZoom: 0,
    step: 0,
    isSoftwareZoom: false,
  }

  refVideo = React.createRef<HTMLVideoElement>();
  refCanvas = React.createRef<HTMLCanvasElement>();
  refDebug = React.createRef<HTMLDivElement>();
  refSlider = React.createRef<HTMLInputElement>();
  stream = undefined as MediaStream | undefined;
  track = undefined as MediaStreamTrack | undefined;
  videoWidth: number | undefined;
  videoHeight: number | undefined;
  // showAdInterval: any;

  componentDidMount() {
    this.props.setVideoElement(this.refVideo);
    this.getStream();
    // this.showAdInterval = window.setInterval(() => {
    //   const adElement = document.querySelector('[data-anchor-status=ready-to-display]') as HTMLElement;
    //   if (adElement) {
    //     adElement.style.display = '';
    //     window.clearInterval(this.showAdInterval);
    //     this.showAdInterval = undefined;
    //   }
    // }, 500);
  }

  componentWillUnmount() {
    if (this.refVideo.current) {
      this.refVideo.current.pause();
      this.refVideo.current.src = '';
      this.stream?.getVideoTracks()[0].stop();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isFreezeOn !== this.props.isFreezeOn && this.refVideo.current) {
      if (this.props.isFreezeOn) {
        this.refVideo.current.pause();
      }
      else {
        this.refVideo.current.play();
      }
    }
    if (prevProps.isFlashOn !== this.props.isFlashOn && this.refVideo.current) {
      //@ts-ignore
      this.track.applyConstraints({ advanced: [ { torch: this.props.isFlashOn } ] }).then(() => {}, () => {});
    }
  }

  getStream(width?: number, height?: number) {
    navigator.mediaDevices?.enumerateDevices().then(devices => {
      var cameras = devices.filter((device) => device.kind === 'videoinput');
      var camera = cameras[cameras.length - 1];
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          width,
          height,
          deviceId: camera.deviceId,
          facingMode: 'environment',
        }
      }).then(stream => {
        if (this.refVideo.current) {
          this.stream = stream;
          this.refVideo.current.srcObject = stream;
          this.refVideo.current.setAttribute('autoplay', '');
          this.refVideo.current.setAttribute('muted', '');
          this.refVideo.current.setAttribute('playsinline', '');
          // alert(`pass: width=${stream.getVideoTracks()[0].getSettings().width} height=${stream.getVideoTracks()[0].getSettings().height}`);
          this.track = stream.getVideoTracks()[0];
          const capabilities = this.track.getCapabilities();
          //@ts-ignore
          if (capabilities.zoom) {
            window.setTimeout(() => this.refVideo.current?.play(), 0);
            //@ts-ignore
            const minZoom = capabilities.zoom.min;
            //@ts-ignore
            const maxZoom = capabilities.zoom.max;
            //@ts-ignore
            const step = capabilities.zoom.step;
            this.setState({ minZoom, maxZoom, step, });
            this.setZoom((maxZoom - minZoom) * 0.5, true);
          }
          else {
            if (!width) {
              this.getStream(3840, 2160);
            }
            else {
              window.setTimeout(() => this.refVideo.current?.play(), 0);
              this.setupSoftwareZoom();
            }
          }
        }
      }).catch(error => {
        alert(error);
      });
    });
  }

  setVideoSize = (isPortrait: boolean) => {
    if (!this.stream) {
      return;
    }
    const settings = this.stream.getVideoTracks()[0].getSettings();
    this.videoWidth = (isPortrait ? Math.min(settings.width!, settings.height!) : Math.max(settings.width!, settings.height!)) ?? 640;
    this.videoHeight = (isPortrait ? Math.max(settings.width!, settings.height!) : Math.min(settings.width!, settings.height!)) ?? 480;
    if (this.refCanvas.current) {
      this.refCanvas.current.width = this.videoWidth;
      this.refCanvas.current.height = this.videoHeight;
    }
  }

  setupSoftwareZoom() {
    if (!this.stream || !this.refCanvas.current) {
      return;
    }
    this.setState({ isSoftwareZoom: true, minZoom: 1, maxZoom: 10, step: 0.1, });
    this.setZoom(5, true);
    const mql = window.matchMedia("(orientation: portrait)");
    this.setVideoSize(mql.matches);
    mql.addEventListener('change', query => {
      this.setVideoSize(query.matches);
    });
    const drawOnCanvas = () => {
      if (!this.refCanvas.current || !this.refVideo.current || !this.videoWidth || !this.videoHeight) {
        return;
      }
      const context = this.refCanvas.current.getContext('2d');
      const scale: number = this.state.zoom ?? 1;
      const sx = (this.videoWidth - this.videoWidth / scale) / 2;
      const sy = (this.videoHeight - this.videoHeight / scale) / 2;
      context?.drawImage(
        this.refVideo.current,
        sx,
        sy,
        this.videoWidth / scale,
        this.videoHeight / scale,
        0,
        0,
        this.videoWidth,
        this.videoHeight);
      this.refDebug.current!.innerHTML = `sx=${sx} sy=${sy} width/scale=${this.videoWidth/scale} height/scale=${this.videoHeight/scale} width=${this.videoWidth} height=${this.videoHeight}`;
      requestAnimationFrame(drawOnCanvas);
    };
    drawOnCanvas();
  }

  setZoom = (zoomValue: number, updateSlider: boolean = false) => {
    if (!this.track) {
      return;
    }
    this.setState({ zoom: zoomValue })
    if (!this.state.isSoftwareZoom) {
      //@ts-ignore
      this.track.applyConstraints({ advanced: [ { zoom: zoomValue } ] });
    } else {
      const video = document.getElementsByTagName('video')[0];
      video.style.position = 'absolute';
      video.style.top = (-50 * (zoomValue - 1)) + '%';
      video.style.left = (-50 * (zoomValue - 1)) + '%';
      video.style.width = 100 * zoomValue + '%';
      video.style.height = 100 * zoomValue + '%';
    }
    if (updateSlider && this.refSlider.current) {
      this.refSlider.current.value = zoomValue.toString();
    }
  }

  render() {
    let videoClasses = "localVideo";
    let canvasClasses = "softwareZoomCanvas";
    if (this.props.isInvertOn) {
      videoClasses += " invert";
      canvasClasses += " invert";
    }
    if (this.state.isSoftwareZoom) {
      videoClasses += " softwareZoom";
      canvasClasses += " softwareZoom";
    }
    return (
      <>
        <video 
          ref={this.refVideo}
          width="400"
          height="300"
          autoPlay={true}
          className={videoClasses} />
        <canvas
          ref={this.refCanvas}
          className={canvasClasses} />
        <Slider
          ref={this.refSlider}
          minValue={this.state.minZoom}
          maxValue={this.state.maxZoom}
          step={this.state.step}
          refSlider={this.refSlider}
          onChange={(zoomValue: number) => this.setZoom(zoomValue)}
        />
        <div ref={this.refDebug} className="debug">debug</div>
      </>
    );
  }
}

export const VideoFrame: any = connect<StateProps, DispatchProps, never, never>(
  (state: RootState) => ({
    isFlashOn: state.ui.isFlashOn,
    isFreezeOn: state.ui.isFreezeOn,
    isInvertOn: state.ui.isInvertOn,
  }),
  (dispatch: IDispatch) => ({
  })
)(VideoFrameImpl);