import * as React from 'react';
import './About.css';
import UAParser from 'ua-parser-js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IDispatch, RootState } from '../store/rootState';
import { UiActions } from '../store/ui/actions';
import HomeIcon from '@mui/icons-material/Home';
import { UiPage } from '../store/ui/reducer';
import { Header } from './Header';
import { Link } from 'react-router-dom';
import Ad from './Ad';

interface StateProps {
}

export interface DispatchProps {
  uiActions: typeof UiActions;
}

type Props = StateProps & DispatchProps;

class AboutImpl extends React.Component<Props> {
  refInstall = React.createRef<HTMLDivElement>();
  deferredPrompt: any;

  componentDidMount() {
  }

  render() {
    const note = new UAParser().getDevice().type !== 'mobile' ? `Note: This site is optimized to be used on a smartphone or a tablet.` : ``;
    return (
      <div className="about_container">
        <Header />
        <div className="about_content">
          <div className="pageTitle">About</div>
          This project was built to demonstrate the ability to use a smartphone camera as a magnifying glass.
          There are many apps like this in Apple App Store and Google's Play Store, but the unique thing about
          this project is that you don't need to install anything on your smartphone to get it working, it's
          just a website.<br />
          Note: This is still work in progress, and not all functions are available yet.
          <Link className="about_homeButton" to="/">
            <HomeIcon /> Home
          </Link>
        </div>
        <div className="about_footer">
          { <Ad dataAdSlot='5296593129' /> }
        </div>
      </div>
    );
  }
}

export const About: any = connect<StateProps, DispatchProps, never, never>(
  (state: RootState) => ({
  }),
  (dispatch: IDispatch) => ({
    uiActions: bindActionCreators(UiActions, dispatch)
  })
)(AboutImpl);